<template>
    <div>
        <v-sheet elevation="5" rounded="lg" class="pa-2" v-if="groupId != null">
            <v-chip v-for="supplier in currentSupplierGroup" :key="supplier" v-if="supplier != supplierName">{{ supplier }}</v-chip>
        </v-sheet>
        <div class="mt-3 d-flex flex-row flex-wrap justify-space-between">
            <v-btn small @click="removeFromGroup()" v-if="groupId != null">{{ $t('message.removeFromGroup') }}</v-btn>
            <v-btn small @click="openExistingGroupList()">{{ $t('message.addToExistingGroup') }}</v-btn>
            <v-btn small @click="openNewGroup()">{{ $t('message.createNewGroup') }}</v-btn>
        </div>
        <v-dialog
            persistent
            v-model="createDialog"
            transition="dialog-bottom-transition"
            overlay=false
            scrollable
            width="500px"
            min-width="500px"
            max-width="600px"
        >
            <v-card>
                <v-card-title class="success white--text justify-space-between">{{ $t('message.createGroup') }}</v-card-title>
                <v-form lazy-validation ref="addGroupForm" v-model="validAddGroupForm">
                    <v-card-text>
                        <v-layout row pt-3>
                            <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.thisSupplier') }}</v-flex>
                            <v-flex xs7 lg7 md6 py-1>{{ supplierName }}</v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.otherGroupMembers') }}</v-flex>
                            <v-flex xs7 lg7 md6 py-1>
                                <v-autocomplete
                                    :items="filteredSuppliers"
                                    :rules="[rules.suppliers]"
                                    chips
                                    clearable
                                    deletable-chips
                                    dense
                                    hide-details="auto"
                                    item-value="Supplier.id"
                                    item-text="Supplier.otsname"
                                    solo
                                    multiple
                                    v-model="newGroup.otherMembers"
                                >
                                    <template v-slot:selection="data">
                                        <v-chip
                                            :input-value="data.selected"
                                            class="my-1"
                                            close
                                            small
                                            v-bind="data.attrs"
                                            @click="data.select"
                                            @click:close="removeOtherMember(data.item)"
                                        >
                                            {{ data.item.Supplier.otsname }}
                                        </v-chip>
                                    </template>
                                </v-autocomplete>
                            </v-flex>
                        </v-layout>
                    </v-card-text>
                </v-form>
                <v-card-actions class="pt-0">
                    <v-spacer></v-spacer>
                    <v-btn color="default" small @click="createDialog = false" >{{$t('message.dismiss')}}</v-btn>
                    <v-btn
                        :loading="loading.create"
                        color="info"
                        small
                        @click="createGroup()"
                    >{{ $t('message.add') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            persistent
            v-model="editDialog"
            transition="dialog-bottom-transition"
            overlay=false
            scrollable
            width="500px"
            min-width="500px"
            max-width="600px"
        >
            <v-card>
                <v-card-title class="success white--text justify-space-between">{{ $t('message.addToExistingGroup') }}</v-card-title>
                <v-card-text>
                    <v-overlay
                        :value="loading.get"
                        absolute
                        opacity="0.15"
                    >
                        <v-row>
                            <v-col class="text-center">
                                <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    size="40"
                                    width="6"
                                ></v-progress-circular>
                            </v-col>
                        </v-row>
                    </v-overlay>
                    <v-data-table
                        :headers="headers"
                        :height="supplierGroups.length < 10 ? (supplierGroups.length * 32) + 40 : 400"
                        :items="supplierGroups"
                        :search="searchTerm"
                        calculate-widths
                        class="appic-table-light specification-table group-list"
                        dense
                        disable-pagination
                        fixed-header
                        hide-default-footer
                        item-key="SupplierGroup.id"
                        show-select
                        single-select
                        v-model="selectedSupplierGroup"
                    >
                        <template v-slot:top>
                            <v-row no-gutters>
                                <v-col cols="12" class="d-flex flex-row align-center pb-3">
                                    <v-text-field
                                        :label="$t('message.filterResults')"
                                        :value="searchTerm"
                                        autocomplete="off"
                                        class="force-text-left mt-2 mb-0"
                                        dense
                                        prepend-inner-icon="mdi-filter-outline"
                                        hide-details
                                        v-model="searchTerm"
                                    >
                                    </v-text-field>
                                    <v-btn
                                        class="ml-3 mt-1"
                                        small
                                        @click="searchTerm = null"
                                    >
                                        <v-icon>refresh</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions class="pt-0">
                    <v-spacer></v-spacer>
                    <v-btn color="default" small @click="editDialog = false" >{{$t('message.dismiss')}}</v-btn>
                    <v-btn
                        :loading="loading.add"
                        color="info"
                        small
                        @click="addToGroup()"
                    >{{ $t('message.add') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import router from "@/router";
import { mapActions, mapGetters } from "vuex";
import { api } from 'Api'
import {log} from "Helpers/helpers";

export default {
    name: "SupplierGroupList",
    props: ['supplierId', 'supplierName', 'groupId'],
    data() {
        return {
            addDialog: false,
            createDialog: false,
            editDialog: false,
            filteredSuppliers: [],
            loading: {
                add: false,
                create: false,
                get: false,
                remove: false
            },
            newGroup: {
                thisMember: null,
                otherMembers: []
            },
            currentSupplierGroup: [],
            searchTerm: null,
            selectedSupplierGroup: [],
            supplierGroups: [],
            rules: {
                suppliers:  v => !!v || this.$t('message.required')
            },
            validAddGroupForm: true
        }
    },
    computed: {
        ...mapGetters('supplier',{
            allSuppliers: 'allSuppliers',
        }),
        headers() {
            return [
                {
                    id: 1,
                    text: this.$t('message.group'),
                    value: 'SupplierGroup.members',
                    class: 'light-green lighten-3 pa-1',
                    searchable: true,
                    sortable: true
                }
            ]
        }
    },
    methods: {
        ...mapActions('supplier',{
            getAllSuppliers: 'getAllSuppliers'
        }),
        addToGroup () {
            if(this.selectedSupplierGroup.length > 0) {
                this.loading.add = true
                const promise = new Promise((resolve, reject) => {
                    api
                        .put('/suppliers/' + this.supplierId + '/group', {
                            group_id: this.selectedSupplierGroup[0]['SupplierGroup']['id']
                        })
                        .then((response) => {
                            if (response.data.status == 'success') {
                                resolve(response.data)
                            } else {
                                reject(response.data)
                            }
                        })
                        .catch((err) => {
                            reject(err)
                        })
                })
                promise
                    .then((response) => {
                        if (response.status == 'success') {
                            this.$toast.success(this.$t('message.successes.supplierAddedToGroup'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.$emit('added-to-group', response.group_id)
                        } else {
                            this.$toast.error(response.message,
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                        this.loading.add = false
                    })
                    .catch((err) => {
                        this.$toast.error(err.message,
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.add = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.noGroupSelected'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        createGroup () {
            if(this.$refs.addGroupForm.validate()) {
                this.loading.create = true
                let suppliers = _.cloneDeep(this.newGroup.otherMembers)
                suppliers.push(parseInt(this.supplierId))
                const promise = new Promise((resolve, reject) => {
                    api
                        .post('/suppliers/group',{
                            group: {
                                supplier_id: this.supplierId,
                                supplier_ids: suppliers
                            }
                        })
                        .then((response) => {
                            if(response.data.status == 'success') {
                                resolve(response.data)
                            } else {
                                reject(response.data)
                            }
                        })
                        .catch((err) => {
                            reject(err)
                        })
                })
                promise
                    .then((response) => {
                        if (response.status == 'success') {
                            this.$toast.success(this.$t('message.successes.supplierGroupCreated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.$emit('group-created', response.group_id)
                            this.loading.create = false
                            this.createDialog = false
                            this.resetCreateForm()
                        } else {
                            this.$toast.error(response.message,
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.create = false
                        }
                    })
                    .catch((err) => {
                        this.$toast.error(err.message,
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.create = false
                    })
            }
        },
        getAllGroups () {
            return new Promise((resolve, reject) => {
                api
                    .get('/suppliers/' + this.supplierId + '/groups')
                    .then((response) => {
                        this.supplierGroups = response.data.data
                        resolve('done')
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        getCurrentGroup () {
            if(this.groupId != null) {
                return new Promise((resolve, reject) => {
                    api
                        .get('/suppliers/' + this.supplierId + '/group/' + this.groupId)
                        .then((response) => {
                            this.currentSupplierGroup = response.data.data
                            resolve('done')
                        })
                        .catch((err) => {
                            reject(err)
                        })
                })
            }
            return true
        },
        openExistingGroupList () {
            this.editDialog = true
        },
        openNewGroup () {
            this.createDialog = true
        },
        removeOtherMember (item) {
            const index = this.newGroup.otherMembers.indexOf(item.Supplier.id)
            if(index >= 0) this.newGroup.otherMembers.splice(index, 1)
        },
        async removeFromGroup () {
            if (await this.$root.$confirm(this.$t('message.removeFromGroup'), this.$t('message.confirmations.continueSupplierRemoveFromGroupAction'), {color: 'orange'})) {
                this.loading.remove = true
                const promise = new Promise((resolve, reject) => {
                    api
                        .delete('/suppliers/' + this.supplierId + '/group/' + this.groupId)
                        .then((response) => {
                            if(response.data.status == 'success') {
                                resolve(response.data)
                            } else {
                                reject(response.data)
                            }
                        })
                        .catch((err) => {
                            reject(err)
                        })
                })
                promise
                    .then((response) => {
                        if(response.status == 'success'){
                            this.$toast.success(this.$t('message.successes.supplierRemovedFromGroup'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.$emit('removed-from-group')
                            this.loading.remove = false
                        } else {
                            this.$toast.error(response.message,
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.remove = false
                        }
                    })
                    .catch((err) => {
                        this.$toast.error(err.message,
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.remove = false
                    })
            }
        },
        resetCreateForm () {
            this.newGroup.thisMember = null
            this.newGroup.otherMembers = []
        }
    },
    mounted() {
        this.newGroup.thisMember = this.supplierId
        if(this.allSuppliers.length == 0) {
            this.getAllSuppliers()
                .then(() => {
                    this.filteredSuppliers = this.allSuppliers.filter(s => s.Supplier.id != this.supplierId)
                    this.filteredSuppliers.sort((a, b) => {
                        const nameA = a.Supplier.otsname
                        const nameB = b.Supplier.otsname
                        return nameA.localeCompare(nameB)
                    })
                    const _this = this
                    setTimeout(function(){
                        _this.getCurrentGroup()
                        _this.getAllGroups()
                    },500)
                })
        } else {
            this.filteredSuppliers = this.allSuppliers.filter(s => s.Supplier.id != this.supplierId)
            this.filteredSuppliers.sort((a, b) => {
                const nameA = a.Supplier.otsname
                const nameB = b.Supplier.otsname
                return nameA.localeCompare(nameB)
            })
            const _this = this
            setTimeout(function(){
                _this.getCurrentGroup()
                _this.getAllGroups()
            },500)
        }
    }
}
</script>

<style scoped>
.group-list::v-deep .v-data-table-header th:first-child {
    background-color: #c5e1a5;
}
</style>